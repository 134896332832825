import { TUserShopData } from 'views/services/analitics';
import { UsersStatistics } from 'views/types/typeUser';
import { create, useStore } from 'zustand';

export enum EAnaliticDataTypeEnum {
  task = 'task',
  giveaway = 'giveaway',
  utm = 'utm',
  referral = 'referral',
  app = 'app',
  wheel = 'wheel',
  puzzle = 'puzzle',
  HAU = 'HAU',
  giveawayShop = 'giveawayShop',
}

interface AnaliticsStore {
  totalUsers: number;
  type: EAnaliticDataTypeEnum;
  id: string | null;
  loading: boolean;
  title: string;
  appStatistics: UsersStatistics | null;
  isGiveawayTask: boolean;
  selectedGiveawayId: string | null;
  startDailyDate: Date;
  endDailyDate: Date;
  giveawayUserData: TUserShopData | null;

  setGiveawayUserData: (data: TUserShopData) => void;
  setIsGiveawayTask: (isGiveawayTask: boolean) => void;
  setSelectedGiveawayId: (selectedGiveawayId: string | null) => void;
  setAppStatistics: (data: UsersStatistics) => void;
  setTotalUsers: (totalUsers: number) => void;
  setId: (id: string) => void;
  setLoading: (loading: boolean) => void;
  setTitle: (title: string) => void;
  setType: (type: EAnaliticDataTypeEnum) => void;
  setAnalitic: (type: EAnaliticDataTypeEnum, id: string, title: string) => void;
  setStartDailyDate: (date: Date) => void;
  setEndDailyDate: (date: Date) => void;
}

const getPreviousDayStartUTC = (): Date => {
  const date = new Date();
  date.setUTCDate(date.getUTCDate() - 1);
  date.setUTCHours(0, 0, 0, 0);
  return date;
};

const getPreviousDayEndUTC = (): Date => {
  const date = new Date();
  date.setUTCDate(date.getUTCDate() - 1);
  date.setUTCHours(23, 59, 59, 999);
  return date;
};

export const analiticsStore = create<AnaliticsStore>(set => ({
  totalUsers: 0,
  type: EAnaliticDataTypeEnum.task,
  id: null,
  title: '',
  loading: false,
  appStatistics: null,
  isGiveawayTask: false,
  selectedGiveawayId: null,
  startDailyDate: getPreviousDayStartUTC(),
  endDailyDate: getPreviousDayEndUTC(),
  giveawayUserData: null,

  setGiveawayUserData: data => set({ giveawayUserData: data }),
  setSelectedGiveawayId: selectedGiveawayId => set({ selectedGiveawayId }),
  setIsGiveawayTask: isGiveawayTask => set({ isGiveawayTask }),
  setAppStatistics: data => set({ appStatistics: data }),
  setId: id => set({ id }),
  setLoading: loading => set({ loading }),
  setTotalUsers: totalUsers => set({ totalUsers }),
  setTitle: title => set({ title }),
  setType: type => set({ type }),
  setAnalitic: (type, id, title) => set({ type, id, title }),
  setStartDailyDate: date => set({ startDailyDate: date }),
  setEndDailyDate: date => set({ endDailyDate: date }),
}));

export const useAnaliticsStore = () => useStore(analiticsStore);
