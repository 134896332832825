import InputField from 'components/fields/InputField';
import { GoldTicketIcon } from 'components/icons/GoldTicketIcon';
import { useEffect, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import { formatNumber } from 'utils/formatNumber';
import {
  analiticsService,
  TGivwawayShopAnalytic,
} from 'views/services/analitics';
import { useAnaliticsStore } from 'views/store/useAnaliticsStore';
import { SetItem } from './SetItem';

type TWheelProps = {
  data: TGivwawayShopAnalytic;
};

export const ShopStatistic = ({ data }: TWheelProps) => {
  const { giveawayUserData, setGiveawayUserData } = useAnaliticsStore();
  const [userName, setUserName] = useState('');
  const [debouncedUserName] = useDebounceValue(userName, 500);
  const [isLoading, setIsLoading] = useState(false);

  const handleUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  };

  const handleSearch = async (userNameToSearch: string) => {
    const nameToSearch = userNameToSearch.trim();
    if (!nameToSearch) {
      setGiveawayUserData(null);
      return;
    }

    try {
      setIsLoading(true);
      await analiticsService.getShopUserData(userNameToSearch);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleSearch(debouncedUserName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUserName]);

  const { totalTickets, setsStats, setsConfig } = data;

  const isThereSets = setsStats.length > 0;

  const sets = isThereSets ? [...setsStats] : [];
  sets.sort((a, b) => b.amount - a.amount);

  const packets = [...data.packagesStats];
  packets.sort((a, b) => b.amount - a.amount);

  return (
    <>
      {data && (
        <div className="w-full flex flex-col gap-8 max-h-[80svh] overflow-y-auto  mt-4">
          <div>
            <h2 className="text-2xl font-semibold mb-2 text-3xl border-b border-gray-200 dark:!border-white/10 pb-2">
              Giveaway Shop Analytics
            </h2>

            <div className="flex gap-1 flex-col w-max">
              <div className="flex gap-4">
                <h3 className="min-w-[100px] font-bold">
                  Golden Tickets Bought:
                </h3>
                <p className="ml-auto">
                  {formatNumber(totalTickets.total_amount)}
                </p>
              </div>
            </div>

            <div className="flex gap-1 flex-col w-max mt-6">
              <h3 className="min-w-[100px] font-bold text-xl mb-2">
                Shop Statistic:
              </h3>

              <div className="flex gap-4">
                <h3 className="min-w-[100px] font-bold">Sets:</h3>
                <div className="flex gap-4 items-center">
                  {sets.length ? (
                    <>
                      {sets.map((set, index) => (
                        <SetItem
                          key={index}
                          boughtAmount={set.amount}
                          goldenTickets={setsConfig[set.type].giveawayTickets}
                          points={setsConfig[set.type].points}
                          tickets={setsConfig[set.type].tickets}
                        />
                      ))}
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              </div>

              <div className="flex gap-4 mt-4">
                <h3 className="min-w-[100px] font-bold">Packets:</h3>
                <div className="">
                  {packets.length ? (
                    <>
                      {packets.map((topPacket, index) => (
                        <div key={index} className="flex gap-2 items-center">
                          <div className="flex gap-2 border border-gray-200 dark:!border-white/10 p-3 rounded-lg">
                            {topPacket.type}x{' '}
                            <GoldTicketIcon width={35} height={25} />
                          </div>

                          <div className="flex gap-2">
                            <p>Bought amount:</p>
                            <p>x{topPacket.amount} </p>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="border-b border-gray-200 dark:!border-white/10 -mt-4" />

          <div className="flex gap-2 items-end hidden">
            <InputField
              label="Golden Tickets in Own"
              id="username"
              extra="w-fit"
              placeholder="username"
              variant="auth"
              value={userName}
              onChange={handleUserNameChange}
            />
            {isLoading && <p>Loading...</p>}
            {giveawayUserData && (
              <div>
                <p className="flex gap-2 items-center">
                  <GoldTicketIcon width={20} height={15} />
                  {giveawayUserData.goldTicketsAmount}{' '}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
