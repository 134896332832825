import { MdBarChart, MdGroup } from 'react-icons/md';

import Widget from 'components/widget/Widget';
import UsersTable from 'views/admin/default/components/UsersTable';
import { HiOutlinePuzzlePiece } from 'react-icons/hi2';
import useSWR from 'swr';
import { usersService } from 'views/services/usersService';
import { combosService } from 'views/services/comboService';
import {
  EAnaliticDataTypeEnum,
  useAnaliticsStore,
} from 'views/store/useAnaliticsStore';
import { useEffect } from 'react';
import { ButtonSM } from 'components/button/Small';
import { PuzzlesList } from './components/PuzzlesList';

const Dashboard = () => {
  const {
    setAnalitic,
    setAppStatistics,
    type: analiticsType,
  } = useAnaliticsStore();

  const { data: statistics, isLoading: isStatisticLoading } = useSWR(
    '/users-statistics',
    () => usersService.getUsersStatistic(),
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
    }
  );

  const { data: secretCombos, isLoading: isComboLoading } = useSWR(
    '/secret-combo/sequence',
    () => combosService.getSecretCombo()
  );

  const handleAnalyticsClick = () => {
    setAnalitic(EAnaliticDataTypeEnum.app, 'null', 'App statistics');
  };

  const handleHAUClick = () => {
    setAnalitic(EAnaliticDataTypeEnum.HAU, 'null', `Hourly Active User's`);
  };

  const handleGoldenAnalyticClick = () => {
    setAnalitic(
      EAnaliticDataTypeEnum.giveawayShop,
      'null',
      `Giveaway Shop Analytics`
    );
  };

  const handleWheelAnalyticsClick = () => {
    setAnalitic(EAnaliticDataTypeEnum.wheel, 'null', 'Spin Wheel statistics');
  };

  const handlePuzzlesAnalyticsClick = () => {
    setAnalitic(
      EAnaliticDataTypeEnum.puzzle,
      'null',
      'Daily-Puzzle statistics'
    );
  };

  useEffect(() => {
    if (analiticsType === 'app') {
      setAppStatistics(statistics);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statistics, analiticsType]);

  return (
    <div>
      <div className="mt-3 w-full flex gap-4 flex-wrap max-w-full">
        <Widget
          icon={<MdGroup className="h-7 w-7" />}
          title={'Total Users'}
          subtitle={
            isStatisticLoading
              ? 'Loading...'
              : statistics?.totalCount.toString()
          }
        />
        <Widget
          icon={<MdGroup className="h-7 w-7" />}
          title={'Daily Active Users'}
          subtitle={
            isStatisticLoading
              ? 'Loading...'
              : statistics?.activeYesterdayCount.toString()
          }
        />
        <Widget
          icon={<HiOutlinePuzzlePiece className="h-7 w-7" />}
          title={'Puzzle Combo'}
          extra="h-[105px]"
          subtitle={
            isComboLoading ? (
              'Loading...'
            ) : (
              <PuzzlesList puzzles={secretCombos} />
            )
          }
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={'Spin Wheel Analytics'}
          subtitle={
            <ButtonSM variant="primary" onClick={handleWheelAnalyticsClick}>
              Open
            </ButtonSM>
          }
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={'Daily-Puzzle Analytics'}
          subtitle={
            <ButtonSM variant="primary" onClick={handlePuzzlesAnalyticsClick}>
              Open
            </ButtonSM>
          }
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={'App Statistics'}
          subtitle={
            <ButtonSM variant="primary" onClick={handleAnalyticsClick}>
              Open
            </ButtonSM>
          }
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={'HAU'}
          subtitle={
            <ButtonSM variant="primary" onClick={handleHAUClick}>
              Open
            </ButtonSM>
          }
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title="Giveaway Shop Analytics"
          subtitle={
            <ButtonSM variant="primary" onClick={handleGoldenAnalyticClick}>
              Open
            </ButtonSM>
          }
        />
      </div>
      <div className="mt-5 grid grid-cols-1 gap-5">
        <div className="w-full">
          <UsersTable />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
