import { GoldTicketIcon } from 'components/icons/GoldTicketIcon';
import { StarIcon } from 'components/icons/StarIcon';
import { TicketIcon } from 'components/icons/TicketIcon';

type TProps = {
  goldenTickets: number;
  points: number;
  tickets: number;
  boughtAmount: number;
};

export const SetItem = ({
  boughtAmount,
  goldenTickets,
  points,
  tickets,
}: TProps) => {
  const isPoints = points > 0;
  const isTickets = tickets > 0;
  const isGoldenTickets = goldenTickets > 0;

  return (
    <>
      <div className="flex  gap-4 border border-gray-200 dark:!border-white/10 p-3 rounded-lg">
        {isGoldenTickets && (
          <p className="flex flex-col items-center text-sm">
            <GoldTicketIcon width={40} height={30} />
            {goldenTickets}
          </p>
        )}

        {isPoints && (
          <p className="flex flex-col items-center text-sm">
            <StarIcon width={40} height={30} />
            {points}
          </p>
        )}

        {isTickets && (
          <p className="flex flex-col items-center text-sm">
            <TicketIcon width={40} height={30} />
            {tickets}
          </p>
        )}
      </div>

      <div className="flex gap-2">
        <p>Bought amount:</p>
        <p>x{boughtAmount} </p>
      </div>
    </>
  );
};
