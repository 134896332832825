export enum EBundleVariant {
  COMMON = 'COMMON',
  PROGRESSIVE = 'PROGRESSIVE',
  DISCONT = 'DISCONT',
}

export enum EBundleSetItemVariant {
  STARS = 'STARS',
  GOLDEN_TICKET = 'GOLDEN_TICKETS',
  DEFAULT_TICKETS = 'DEFAULT_TICKETS',
}

export type TBundleSetItem = {
  starsPrice: number;
  pointsCount: number;
  ticketsCount: number;
  giveawayTicketsCount: number;
};

export type TBundleSet = TBundleSetItem[];

export type TBundle = {
  shopQuest: {
    isActive: boolean;
    id: string;
    title: string;
    description: string;
    imageUrl: string;
    endDate?: string;
    type: EBundleVariant; // adding from frontend
  };
  bundles: TBundleSet;
};

export type TPopUpCreatingType = 'new' | 'edit' | null;
