import * as Dialog from '@radix-ui/react-dialog';
import { MdClose } from 'react-icons/md';

import clsx from 'clsx';
import {
  EAnaliticDataTypeEnum,
  useAnaliticsStore,
} from 'views/store/useAnaliticsStore';
import { useEffect, useState } from 'react';
import {
  analiticsService,
  TAnaliticsData,
  TDateStatistics,
  TGivwawayShopAnalytic,
  TPuzzleAnaliticsData,
  TWheelAnaliticsData,
} from 'views/services/analitics';
import { NonAppAnalitics } from './NonAppAnalitics';
import { utmService } from 'views/services/utmService';
import { TUTMAnalyticsData } from 'views/types/analytics';
import { AppStasistics } from './AppStatistic';
import { WheelStats } from './WheelStats';
import { PuzzleStats } from './PuzzleStats';
import { MdArrowCircleLeft } from 'react-icons/md';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { HAUStatistics } from './HAUStatistics';
import { ShopStatistic } from './ShopStatistic';

type TAnaliticDataVariants =
  | TAnaliticsData
  | TUTMAnalyticsData
  | TWheelAnaliticsData
  | TPuzzleAnaliticsData
  | TDateStatistics[]
  | TGivwawayShopAnalytic
  | null;

export const AnaliticsDialog = () => {
  const {
    id,
    type,
    setId,
    loading,
    setLoading,
    title,
    setTitle,
    appStatistics,
    selectedGiveawayId,
    isGiveawayTask,
    setIsGiveawayTask,
    setAnalitic,
    startDailyDate,
    endDailyDate,
  } = useAnaliticsStore();
  const [data, setData] = useState<TAnaliticDataVariants>(null);

  const loadData = (dataLoadCallBack: () => Promise<TAnaliticDataVariants>) => {
    setLoading(true);
    dataLoadCallBack()
      .then(setData)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (id) {
      if (type === EAnaliticDataTypeEnum.giveaway) {
        loadData(() => analiticsService.getGiveawayStats(id));
      }
      if (type === EAnaliticDataTypeEnum.task) {
        loadData(() => analiticsService.getTasksStats(id));
      }
      if (type === EAnaliticDataTypeEnum.utm) {
        loadData(() => utmService.getUTMStats(id));
      }
      if (type === EAnaliticDataTypeEnum.wheel) {
        loadData(() => analiticsService.getWheelStats());
      }
      if (type === EAnaliticDataTypeEnum.puzzle) {
        loadData(() => analiticsService.getPuzzleStats());
      }
      if (type === EAnaliticDataTypeEnum.HAU) {
        loadData(() =>
          analiticsService.getDailyStats(startDailyDate, endDailyDate)
        );
      }
      if (type === EAnaliticDataTypeEnum.giveawayShop) {
        loadData(() => analiticsService.getGiveawayShopStats());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, startDailyDate, endDailyDate]);

  const handleClose = () => {
    setData(null);
    setId(null);
    setTitle('');
    setIsGiveawayTask(false);
  };

  const handleBack = () => {
    setIsGiveawayTask(false);
    setAnalitic(EAnaliticDataTypeEnum.giveaway, selectedGiveawayId, title);
  };

  const getAnaliticsType = (type: EAnaliticDataTypeEnum) => {
    switch (type) {
      case EAnaliticDataTypeEnum.task:
        return 'task';
      case EAnaliticDataTypeEnum.giveaway:
        return 'giveaway';
      case EAnaliticDataTypeEnum.utm:
        return 'utmSource';
      case EAnaliticDataTypeEnum.referral:
        return 'empty';
      default:
        return 'empty';
    }
  };

  const isAppStatistics = type === EAnaliticDataTypeEnum.app;
  const isWheelStatistics = type === EAnaliticDataTypeEnum.wheel;
  const isPuzzleStatistics = type === EAnaliticDataTypeEnum.puzzle;
  const isHAUAnalitics = type === EAnaliticDataTypeEnum.HAU;
  const isShopStatistics = type === EAnaliticDataTypeEnum.giveawayShop;

  const isThereData =
    (data &&
      'notCompletedUsersCount' in data &&
      data?.notCompletedUsersCount > 0) ||
    (data && 'completedUsersCount' in data && data?.completedUsersCount > 0) ||
    (data && 'totalUsersCount' in data && data?.totalUsersCount > 0) ||
    (data && 'usersTotalCount' in data && data?.usersTotalCount > 0) ||
    (data &&
      'totalUsersCompletedGeneral' in data &&
      data?.totalUsersCompletedGeneral > 0) ||
    (data && isHAUAnalitics) ||
    (data && isShopStatistics);

  const showNonAppStatistics =
    isThereData &&
    !isAppStatistics &&
    !isWheelStatistics &&
    !isPuzzleStatistics &&
    !isHAUAnalitics &&
    !isShopStatistics;

  return (
    <>
      <Dialog.Root open={!!id} onOpenChange={handleClose} modal>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-gray-800 bg-opacity-50" />
          <Dialog.Content
            onInteractOutside={e => {
              if ((e.target as HTMLElement).closest('.chakra-toast')) {
                e.preventDefault();
              }
            }}
            className={clsx(
              'data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] z-[60] max-h-[85vh] w-[90vw] max-w-[800px]',
              'translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px]',
              'shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px]',
              'focus:outline-none dark:bg-gray-800 dark:text-white',
              'flex'
            )}
          >
            {loading && (
              <div className="w-full min-h-[85vh] flex items-center justify-center">
                <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
              </div>
            )}
            {!isThereData && !loading && !isAppStatistics && <p>No data</p>}
            {showNonAppStatistics && !loading && (
              <NonAppAnalitics
                data={data as TAnaliticsData | TUTMAnalyticsData}
                id={id}
                type={type}
                extraAnaliticsType={getAnaliticsType(type)}
                title={title}
              />
            )}

            {isAppStatistics && appStatistics && (
              <AppStasistics data={appStatistics} />
            )}

            {isWheelStatistics && data && (
              <WheelStats data={data as TWheelAnaliticsData} />
            )}

            {isPuzzleStatistics && data && (
              <PuzzleStats data={data as TPuzzleAnaliticsData} />
            )}

            {isHAUAnalitics && data && !loading && (
              <HAUStatistics data={data as TDateStatistics[]} />
            )}

            {isShopStatistics && data && !loading && (
              <ShopStatistic data={data as TGivwawayShopAnalytic} />
            )}

            {isGiveawayTask && (
              <button
                className="flex items-center gap-2 absolute left-[10px] top-[10px] text-violet11 hover:bg-violet4 focus:shadow-violet7 focus:outline-none"
                onClick={handleBack}
              >
                <MdArrowCircleLeft className="w-5 h-5" />
                Back
              </button>
            )}
            <Dialog.Close asChild>
              <button
                id="task-modal-close-btn"
                type="button"
                className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                aria-label="Close"
              >
                <MdClose />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
